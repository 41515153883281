import { makeAppointment } from "@/api/appointmentt"
import { validatePhone } from '@/utils/validator.js'
import { Toast } from 'vant';
export default {
    data() {
        return {
            loading: false,
            dialogWidth: '50%',
            formLabelWidth: '110px',

            form: {
                userName: '',
                mobilePhone: '',
                shortName: "",
                programme: []
            },
            rules: {
                userName: [{ required: true, message: '请输入正确的姓名', trigger: 'blur' }],
                mobilePhone: [
                    {
                        required: true,
                        validator: validatePhone,
                        trigger: 'blur'
                    }
                ],
                shortName: [
                    { required: true, message: '请输入单位名称', trigger: 'blur' }
                ],
                programme: [
                    { required: true, message: '请选择关注模块', }
                ]
            },
            checkList: ['选中且禁用', '复选框 A'],
            programmeList: [
                {
                    name: '民主测评',
                    value: 2
                },
                {
                    name: '360考核',
                    value: 3
                },
                {
                    name: '民主推荐',
                    value: 4
                },
                {
                    name: '同行评议',
                    value: 5
                },
                {
                    name: '在线考试',
                    value: 14
                },
                {
                    name: '绩效管理',
                    value: 6
                },
                // {
                //     name: '干部信息',
                //     value: 7
                // },
                {
                    name: '职称评审',
                    value: 8
                },
                // {
                //     name: '干部监督',
                //     value: 9
                // },
                // {
                //     name: '干部考核',
                //     value: 10
                // },
                {
                    name: '选拔任用',
                    value: 13
                },
                {
                    name: '领导力评估',
                    value: 1
                },
                {
                    name: '其它',
                    value: 12
                }
            ],
        };

    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default: "标题"
        },
        cancelText: {
            type: String,
            default: "取消"
        },
        comfirmText: {
            type: String,
            default: "提交"
        },
        showFooter: {
            type: Boolean,
            default: true
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        comfirmDisabled: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: "30%"
        },
        showClose: {
            type: Boolean,
            default: false
        },
        showCancel: {
            type: Boolean,
            default: true
        },
        screenWidth: {
            default: document.body.clientWidth
        }
    },
    methods: {
        comfirm() {
            // if (this.form.programme.length == 0) {
            // this.$message({
            //     message: '关注模块不能为空',
            //     type: 'error'
            // })
            //     return
            // }
            this.$refs['yuyue'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    let str = ''
                    this.form.programme.forEach(item => {
                        this.programmeList.forEach(programme => {
                            if (item == programme.name) {
                                str += programme.value + ','
                            }
                        })
                    })
                    let params = {
                        userName: this.form.userName,
                        mobilePhone: this.form.mobilePhone,
                        shortName: this.form.shortName,
                        serviceType: str.slice(0, -1)
                    }
                    makeAppointment(params).then(res => {
                        if (res.success) {

                            var info = navigator.userAgent
                            var isPhone = /mobile|iphone|android|ipod/i.test(info)
                            if (!isPhone) {
                                this.$message({
                                    message: '预约成功',
                                    type: 'success'
                                })
                            } else {
                                Toast.success('预约成功');
                            }


                            this.$refs['yuyue'].resetFields();


                            this.loading = false

                            this.$emit("cancel");
                        } else {

                            this.loading = false
                        }
                    })
                } else {
                    return false
                }
            })

        },
        cancel() {
            this.$refs['yuyue'].resetFields();

            this.$emit("cancel");
        },
        setDialogWidth() {
            this.formLabelWidth = '110px'

            if (this.screenWidth <= 768) {
                this.dialogWidth = '100%'
            } else if (this.screenWidth <= 1024) {
                this.dialogWidth = '60%'
            } else {
                this.dialogWidth = '50%'
            }
        }
    },
    watch: {
        screenWidth: {
            handler(n, o) {
                this.setDialogWidth()
            },
            immediate: true
        }
    },
};
